import {z} from "zod";
import {PublitEnvironment, firebaseTimestampSchema} from "./shared";

export interface AuthUser {
  id: string;
  displayName?: string;
  email?: string;
  emailVerified: boolean;
}

export const authUserSchema = z.strictObject({
  id: z.string(),
  displayName: z.string().nullish(),
  email: z.string().nullish(),
  emailVerified: z.boolean(),
});

export type AuthUserType = z.infer<typeof authUserSchema>;

export enum LogLevel {
  info = "info",
  error = "error",
  exception = "exception",
}

export interface LogLine {
  service: string;
  level: LogLevel;

  namespace: string;
  event: string;
  payload: string; // object is stringified

  VERSION: string;
  ENV: PublitEnvironment;
  TIME: number;
}

export const feedbackTypeEnumSchema = z.enum(["issue", "idea", "other"]);

export type FeedbackTypeEnumType = z.infer<typeof feedbackTypeEnumSchema>;

export const feedbackSchema = z.strictObject({
  type: feedbackTypeEnumSchema,
  route: z.string(),
  text: z.string(),
  userID: z.string().nullish(),
  seen: z.boolean(),
  resolved: z.boolean(),
  timestamp: z.number(),
});

export type FeedbackType = z.infer<typeof feedbackSchema>;

export const secureInfoSubscriptionSchema = z.strictObject({
  expiresAt: z.number().nullish(),
  stripeSubscriptionID: z.string().nullish(),
  current: z.boolean(),

  manuallyUpdated: z.boolean().nullish(),
  notes: z.string().nullish(),
});

export type SecureInfoSubscriptionType = z.infer<
  typeof secureInfoSubscriptionSchema
>;

export const secureInfoSchema = z.strictObject({
  siteID: z.string().optional(),
  directoryID: z.string().optional(),

  activeSubscription: secureInfoSubscriptionSchema.optional(),

  stripeCustomerID: z.string().nullish(),

  handledWebhooks: z.array(z.string()).optional(),

  created: firebaseTimestampSchema.optional(),
  updated: firebaseTimestampSchema.optional(),
});

export type SecureInfoType = z.infer<typeof secureInfoSchema>;

export const contactMessageContentSchema = z.strictObject({
  name: z.string(),
  email: z.string(),
  company: z.string().optional(),
  body: z.string(),
});

export type ContactMessageContentType = z.infer<
  typeof contactMessageContentSchema
>;

export const contactMessageSchema = z.strictObject({
  siteID: z.string().optional(),
  directoryID: z.string().optional(),

  content: contactMessageContentSchema,

  createdAt: z.number(),
});

export type ContactMessageType = z.infer<typeof contactMessageSchema>;

export interface PartnerDiscount {
  url: string;
  name: string;

  couponID: string;
  discount: number;
  singleUse: boolean;

  prepaid: boolean;
  customerEmail?: string | null;
  stripeCustomerID?: string | null;
  prepaidPaymentAmount?: number | null;
  prepaidPaymentDiscount?: number | null;

  notes: string;

  createdAt: number;
}
