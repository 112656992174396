export const slugify = (str?: string) => {
  if (!str) return "";

  return str
    .toLowerCase()
    .replace(/[^\w- ]+/g, "") // Allow hyphens
    .replace(/ +/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-") // Replace consecutive hyphens with a single hyphen
    .replace(/^-|-$/, ""); // Remove leading and trailing hyphens
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const titlefy = (str?: string) => {
  if (!str) return "";

  const needsCapitalization =
    str.toUpperCase() !== str || str.toLowerCase() !== str;

  return str
    .split(/[-_\s]+/)
    .map(s => s.trim())
    .filter(s => s.length > 0)
    .map(s => (needsCapitalization ? capitalize(s) : s))
    .join(" ");
};
