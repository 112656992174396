import {LiveMutation} from "../../shared/types";
import {RichTextContent} from "../../shared/rich-text";
import {LiveDirectory} from "../directory";
import {
  CustomFieldKeyType,
  SortDirectionType,
  naturalCompareByKey,
} from "../../../../common";
import {DirectoryGroup} from "../info";
import {ensureWithinBounds} from "../../shared/helpers";
import {buildUploadedImageMutations} from "../../shared/uploaded-image";
import {buildSocialMediaLinkMutations} from "../../shared/social-media-link";

export const directoryMutations = {
  title(updatedTitle: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.title = updatedTitle;
    };
  },
  tagline(updatedTagline: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.tagline = updatedTagline;
    };
  },
  description(
    rawRichText: Record<string, unknown>
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      const updatedRichText = RichTextContent.fromJSON(rawRichText);

      if (updatedRichText) {
        directory.info.description = updatedRichText;
      }
    };
  },
  color(updatedColor: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.color = updatedColor;
    };
  },
  showAllGroupsOnHome(show: boolean): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.showAllGroupsOnHome = show;
    };
  },
  hideSearchFiltersOnHome(show: boolean): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.hideSearchFiltersOnHome = !show;
    };
  },
  logo: buildUploadedImageMutations<LiveDirectory>(d => d.info.logo),
  coverImage: buildUploadedImageMutations<LiveDirectory>(
    d => d.info.coverImage
  ),
  socialMediaLinks: buildSocialMediaLinkMutations<LiveDirectory>(
    dir => dir.info.socialMediaLinks
  ),
  contactEmail(updatedEmail: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.contactInfo.email = updatedEmail;
    };
  },
  showContactEmailAddress(
    updatedShowEmailAddress: boolean
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.contactInfo.showEmailAddress = updatedShowEmailAddress;
    };
  },
  visibilityIsPrivate(
    updatedIsPrivate: boolean
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.visibility.isPrivate = updatedIsPrivate;
    };
  },
  visibilityPassword(
    updatedPassword: string
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.visibility.password = updatedPassword;
    };
  },
  externalLinkLabel(updatedLabel: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.navigation.externalLink.label = updatedLabel;
    };
  },
  externalLinkURL(updatedURL: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.navigation.externalLink.url = updatedURL;
    };
  },
  layout(
    updatedLayout: "compact" | "large"
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.layout = updatedLayout;
    };
  },
  customFieldTitle(
    key: CustomFieldKeyType
  ): (updatedTitle: string) => LiveMutation<LiveDirectory, void> {
    return updatedTitle => {
      return directory => {
        directory.info.fieldStructure[`field${key}Title`] = updatedTitle;
      };
    };
  },
  removeCustomField(
    key: CustomFieldKeyType
  ): () => LiveMutation<LiveDirectory, void> {
    return () => {
      return directory => {
        directory.info.fieldStructure[`field${key}Title`] = "";
      };
    };
  },
  moveCustomField(
    index: number,
    adjustment: "up" | "down"
  ): () => LiveMutation<LiveDirectory, void> {
    return () => {
      return directory => {
        ensureWithinBounds(
          index,
          directory.info.fieldStructure.fieldOrder,
          adjustment
        );

        const positionChange = adjustment === "up" ? -1 : 1;

        const thisItem = directory.info.fieldStructure.fieldOrder[index];
        const otherItem =
          directory.info.fieldStructure.fieldOrder[index + positionChange];
        directory.info.fieldStructure.fieldOrder[index] = otherItem;
        directory.info.fieldStructure.fieldOrder[index + positionChange] =
          thisItem;
      };
    };
  },
  groupTitle(updatedTitle: string): LiveMutation<LiveDirectory, void> {
    return directory => {
      directory.info.groupTitle = updatedTitle;
    };
  },
  sortGroups(direction: SortDirectionType): LiveMutation<LiveDirectory, void> {
    return directory => {
      const keyMap = {
        "a-z": "name",
        "z-a": "name",
      } as const;

      const key = keyMap[direction as keyof typeof keyMap];

      // if we do not support sorting in that direction, ignore
      if (!key) return;

      directory.info.groupSortDirection = direction;

      directory.info.groups.sort(naturalCompareByKey(key));

      // if we are sorting in reverse, reverse the array
      if (direction === "z-a") {
        directory.info.groups.reverse();
      }
    };
  },
  newGroup(): LiveMutation<LiveDirectory, DirectoryGroup> {
    return directory => {
      const group = new DirectoryGroup();
      directory.info.groups.unshift(group);

      // when we add a new group, we reset the sort direction to manual
      directory.info.groupSortDirection = "manual";

      return group;
    };
  },
  groupName(
    groupID: string
  ): (name: string) => LiveMutation<LiveDirectory, void> {
    return updatedName => {
      return directory => {
        const index = directory.info.groups.findIndex(g => g.id === groupID);

        if (index === -1) {
          throw new Error(`Could not find group with ID ${groupID}`);
        }

        directory.info.groups[index].name = updatedName;
      };
    };
  },
  groupSlug(
    groupID: string,
    updatedSlug: string
  ): LiveMutation<LiveDirectory, void> {
    return directory => {
      const index = directory.info.groups.findIndex(g => g.id === groupID);

      if (index === -1) {
        throw new Error(`Could not find group with ID ${groupID}`);
      }

      directory.info.groups[index].slug = updatedSlug;
    };
  },
  moveGroup(
    groupID: string,
    adjustment: "up" | "down"
  ): () => LiveMutation<LiveDirectory, void> {
    return () => {
      return directory => {
        const index = directory.info.groups.findIndex(g => g.id === groupID);

        ensureWithinBounds(index, directory.info.groups, adjustment);

        const positionChange = adjustment === "up" ? -1 : 1;

        const thisGroup = directory.info.groups[index];
        const otherGroup = directory.info.groups[index + positionChange];
        directory.info.groups[index] = otherGroup;
        directory.info.groups[index + positionChange] = thisGroup;

        // when we move a group, we reset the sort direction to manual
        directory.info.groupSortDirection = "manual";
      };
    };
  },
  removeGroup(groupID: string): () => LiveMutation<LiveDirectory, void> {
    return () => {
      return directory => {
        const index = directory.info.groups.findIndex(g => g.id === groupID);

        if (index === -1) {
          throw new Error(`Could not find group with ID ${groupID}`);
        }

        directory.info.groups.splice(index, 1);
      };
    };
  },
};

// export class CompleteStageMutation implements Mutation<Site> {
//   private newlyCompletedStage: COMPLETED_STAGE

//   constructor(stage: COMPLETED_STAGE) {
//     this.newlyCompletedStage = stage;
//   }

//   async mutate(site: Site) {
//     site.completedStages.push(this.newlyCompletedStage);
//   }
// }
