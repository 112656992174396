import {Site} from "../site";
import {createWorkItemsMutations, WorkSectionMutations} from "./work-sections";
import {blogging} from "./blogging";
import {education} from "./education";

import {SiteResume} from "../data";
import {SiteThemeName} from "../../../../common";
import {LiveMutation} from "../../shared/types";
import {RichTextContent} from "../../shared/rich-text";
import {ImageCrop} from "../../shared/image-crop";

export type {WorkSectionMutations};

const socials = {
  linkedIn(updatedLink: string): LiveMutation<Site, void> {
    return site => {
      site.data.socialLinks.linkedIn = updatedLink;
    };
  },
  twitter(updatedLink: string): LiveMutation<Site, void> {
    return site => {
      site.data.socialLinks.twitter = updatedLink;
    };
  },
  facebook(updatedLink: string): LiveMutation<Site, void> {
    return site => {
      site.data.socialLinks.facebook = updatedLink;
    };
  },
  instagram(updatedLink: string): LiveMutation<Site, void> {
    return site => {
      site.data.socialLinks.instagram = updatedLink;
    };
  },
  youtube(updatedLink: string): LiveMutation<Site, void> {
    return site => {
      site.data.socialLinks.youtube = updatedLink;
    };
  },
};

export const mutations = {
  name(updatedName: string): LiveMutation<Site, void> {
    return site => {
      site.data.about.name = updatedName;
    };
  },
  email(updatedEmail: string): LiveMutation<Site, void> {
    return site => {
      site.data.about.email = updatedEmail;
    };
  },
  hideEmail(hideEmail: boolean): LiveMutation<Site, void> {
    return site => {
      site.data.about.hideEmail = hideEmail;
    };
  },
  position(updatedPosition: string): LiveMutation<Site, void> {
    return site => {
      site.data.about.position = updatedPosition;
    };
  },
  degree(updatedDegree: string): LiveMutation<Site, void> {
    return site => {
      site.data.about.degree = updatedDegree;
    };
  },
  institution(updatedInstitution: string): LiveMutation<Site, void> {
    return site => {
      site.data.about.institution = updatedInstitution;
    };
  },
  themeColor(color: string, secondary?: string): LiveMutation<Site, void> {
    // TODO: throw error color is not a known theme color
    return site => {
      site.data.theme.color = color;
      site.data.theme.secondaryColor = secondary ?? null;
    };
  },
  themeName(name: SiteThemeName): LiveMutation<Site, void> {
    return site => {
      site.data.theme.name = name;
    };
  },
  biography(rawRichText: Record<string, unknown>): LiveMutation<Site, void> {
    return site => {
      const updatedRichText = RichTextContent.fromJSON(rawRichText);

      if (updatedRichText) {
        site.data.biographyRichText = updatedRichText;
      }
    };
  },
  addResume(name: string, uploadID: string): LiveMutation<Site, void> {
    return site => {
      site.data.resume = new SiteResume(name, uploadID);
    };
  },
  resumeTitle(title: string): LiveMutation<Site, boolean> {
    return site => {
      if (site.data.resume) {
        site.data.resume.sectionTitle = title;
        return true;
      } else {
        return false;
      }
    };
  },
  removeResume(): LiveMutation<Site, void> {
    return site => {
      site.data.resume = undefined;
    };
  },
  contactImage(crop?: ImageCrop): LiveMutation<Site, void> {
    return site => {
      site.data.imageCrops.contact = crop;
    };
  },
  profileImage(crop?: ImageCrop): LiveMutation<Site, void> {
    return site => {
      site.data.imageCrops.profile = crop;
    };
  },
  domainChoice(index: number, url: string): LiveMutation<Site, void> {
    return site => {
      site.domainChoices[index] = url;
    };
  },
  socials,
  education,
  blogging,
  primaryWorks: createWorkItemsMutations(d => d.primaryWorks),
  secondaryWorks: createWorkItemsMutations(d => d.secondaryWorks),
};
