export const naturalSortByKey = <T, Key extends keyof T>(
  items: T[],
  key: Key
) => {
  items.sort(naturalCompareByKey(key));
};

export const naturalSortByValue = <T, ValueType>(
  items: T[],
  fetcher: (item: T) => ValueType
) => {
  items.sort(naturalCompareByValue(fetcher));
};

export const naturalCompareByKey =
  <T, Key extends keyof T>(key: Key) =>
  (a: T, b: T): number => {
    if (a[key] == b[key]) return 0;

    return a[key] < b[key] ? -1 : 1;
  };

export const naturalCompareByValue =
  <T, ValueType>(f: (item: T) => ValueType) =>
  (a: T, b: T): number => {
    if (f(a) == f(b)) return 0;

    return f(a) < f(b) ? -1 : 1;
  };
