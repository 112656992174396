import {PublitEnvironment, PublitService, LogLine, LogLevel} from "./types";

const VERSION = "2.0";

export interface LogAdapter {
  appendLog: (line: LogLine) => Promise<string>;
}

export default class Logger {
  private service: PublitService;
  private environment: PublitEnvironment;
  private adapter: LogAdapter;

  constructor(
    service: PublitService,
    env: PublitEnvironment,
    adapter: LogAdapter
  ) {
    this.service = service;
    this.environment = env;
    this.adapter = adapter;
  }

  public info(
    namespace: string,
    event: string,
    payload: Record<string, unknown>
  ) {
    const line = this.build(LogLevel.info, namespace, event, payload);
    return this.flush(line);
  }

  public error(
    namespace: string,
    event: string,
    payload: Record<string, unknown>
  ) {
    const line = this.build(LogLevel.error, namespace, event, payload);
    return this.flush(line);
  }

  // This should only be used when we do not expect a function to throw any sort of error.
  // This represents an "unhandled exception" that is very bad.
  public exception(
    namespace: string,
    event: string,
    payload: Record<string, unknown>
  ) {
    const line = this.build(LogLevel.exception, namespace, event, payload);
    return this.flush(line);
  }

  build(
    level: LogLevel,
    namespace: string,
    event: string,
    payload: Record<string, unknown>
  ): LogLine {
    return {
      service: this.service,
      level,

      namespace,
      event,
      payload: JSON.stringify(payload),

      VERSION,
      ENV: this.environment,
      TIME: new Date().getTime(),
    };
  }

  async flush(line: LogLine) {
    if (this.environment == PublitEnvironment.dev) {
      console.log("[s-log]", {
        ...line,
        payload: JSON.parse(line.payload),
      });
      return;
    }

    return this.adapter.appendLog(line);
  }
}
