import "reflect-metadata";
import {jsonObject, jsonMember, jsonArrayMember, TypedJSON} from "typedjson";
import type {
  DraftInlineStyleType,
  DraftEntityType,
  DraftEntityMutability,
  DraftBlockType,
} from "draft-js";

@jsonObject
class InlineStyleRange {
  // TypedJSON is dumb sometimes
  @jsonMember({serializer: t => t, deserializer: v => v})
  style: DraftInlineStyleType;

  @jsonMember offset: number;
  @jsonMember length: number;

  constructor(style: DraftInlineStyleType, offset: number, length: number) {
    this.style = style;
    this.offset = offset;
    this.length = length;
  }
}

@jsonObject
class EntityRange {
  @jsonMember key: number;
  @jsonMember offset: number;
  @jsonMember length: number;

  constructor(key: number, offset: number, length: number) {
    this.key = key;
    this.offset = offset;
    this.length = length;
  }
}

@jsonObject
class Entity<T = {[key: string]: any}> {
  @jsonMember type: DraftEntityType;
  @jsonMember mutability: DraftEntityMutability;
  @jsonMember data: T;

  constructor(
    type: DraftEntityType,
    mutability: DraftEntityMutability,
    data: T
  ) {
    this.type = type;
    this.mutability = mutability;
    this.data = data;
  }
}

@jsonObject
class ContentBlock {
  @jsonMember key: string;

  // TypedJSON is dumb sometimes
  @jsonMember({serializer: t => t, deserializer: v => v})
  type: DraftBlockType;

  @jsonMember text: string;
  @jsonMember depth: number;
  @jsonArrayMember(InlineStyleRange) inlineStyleRanges: InlineStyleRange[];
  @jsonArrayMember(EntityRange) entityRanges: EntityRange[];
  @jsonMember data?: {[key: string]: any};

  constructor(
    key: string,
    type: DraftBlockType,
    text: string,
    depth: number,
    inlineStyleRanges: InlineStyleRange[],
    entityRanges: EntityRange[],
    data?: {[key: string]: any}
  ) {
    this.key = key;
    this.type = type;
    this.text = text;
    this.depth = depth;
    this.inlineStyleRanges = inlineStyleRanges;
    this.entityRanges = entityRanges;
    this.data = data;
  }
}

@jsonObject
export class RichTextContent {
  @jsonArrayMember(ContentBlock) blocks: ContentBlock[] = [];
  @jsonMember entityMap: {[key: string]: Entity} = {};

  static fromJSON(object: any): RichTextContent | undefined {
    const serializer = new TypedJSON(RichTextContent);
    return serializer.parse(object);
  }
}
