import {LiveMutation} from "../../shared/types";
import {RichTextContent} from "../../shared/rich-text";
import {CustomFieldKeyType} from "../../../../common";
import {LiveDirectoryItem} from "../item";
import {buildSocialMediaLinkMutations} from "../../shared/social-media-link";
import {buildUploadedImageMutations} from "../../shared/uploaded-image";

export const directoryItemMutations = {
  name(updatedName: string): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.name = updatedName;
    };
  },
  groupID(updatedGroupID: string): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.groupID = updatedGroupID;
    };
  },
  slug(updatedSlug: string): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.slug = updatedSlug;
    };
  },
  featured(updatedFeatured: boolean): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.featured = updatedFeatured;
    };
  },
  bio(
    rawRichText: Record<string, unknown>
  ): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      const updatedRichText = RichTextContent.fromJSON(rawRichText);

      if (updatedRichText) {
        item.bio = updatedRichText;
      }
    };
  },
  customField(
    key: CustomFieldKeyType
  ): (value: string) => LiveMutation<LiveDirectoryItem, void> {
    return value => {
      return item => {
        item[`customField${key}`] = value;
      };
    };
  },
  privateInfoEmail(email: string): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.privateInfo.email = email;
    };
  },
  privateInfoNotes(notes: string): LiveMutation<LiveDirectoryItem, void> {
    return item => {
      item.privateInfo.notes = notes;
    };
  },
  profileImage: buildUploadedImageMutations<LiveDirectoryItem>(
    item => item.profileImage
  ),
  socialMediaLinks: buildSocialMediaLinkMutations<LiveDirectoryItem>(
    item => item.socialMediaLinks
  ),
};
