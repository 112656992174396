import {z} from "zod";
import {
  cropHistorySchema,
  firebaseTimestampSchema,
  imageCropSchema,
  richTextSchema,
} from "../shared";
// SITE

// COMPLETED_STAGE
export const completedStageSchema = z.enum([
  "signup",
  "purchase",
  "domain-purchased",
  "domain-configured",
]);
export type CompletedStageType = z.infer<typeof completedStageSchema>;

// SiteThemeName
export const siteThemeNameSchema = z.enum([
  "classic",
  "minimal",
  "modern",
  "visual",
]);
export type SiteThemeNameType = z.infer<typeof siteThemeNameSchema>;

// SiteDeploymentInfoProtocol
export const siteDeploymentInfoSchema = z.strictObject({
  firstPublishedAt: firebaseTimestampSchema.optional(),
  publishedAt: firebaseTimestampSchema.optional(),
  currentSnapshotID: z.string().optional(),
});
export type SiteDeploymentInfoType = z.infer<typeof siteDeploymentInfoSchema>;

// SiteThemeProtocol
export const siteThemeSchema = z.strictObject({
  color: z.string(),
  secondaryColor: z.string().nullish(),
  name: siteThemeNameSchema.default("classic"),
});
export type SiteThemeType = z.infer<typeof siteThemeSchema>;

// SiteAboutProtocol
export const siteAboutSchema = z.strictObject({
  name: z.string(),
  position: z.string().nullish(),
  institution: z.string().nullish(),
  degree: z.string().nullish(),
  email: z.string().nullish(),
  hideEmail: z.boolean().nullish(),
});
export type SiteAboutType = z.infer<typeof siteAboutSchema>;

// SiteSocialLinksProtocol
export const siteSocialLinksSchema = z.strictObject({
  twitter: z.string().nullish(),
  facebook: z.string().nullish(),
  linkedIn: z.string().nullish(),
  instagram: z.string().nullish(),
  youtube: z.string().nullish(),
});
export type SiteSocialLinksType = z.infer<typeof siteSocialLinksSchema>;

// SiteImageCropsProtocol
export const siteImageCropsSchema = z.strictObject({
  contact: imageCropSchema.nullish(),
  profile: imageCropSchema.nullish(),
});
export type SiteImageCropsType = z.infer<typeof siteImageCropsSchema>;

// SiteWorkItemProtocol
export const siteWorkItemSchema = z.strictObject({
  id: z.string(),
  title: z.string().nullish(),
  titleRichText: richTextSchema.nullish(),
  href: z.string(),
});
export type SiteWorkItemType = z.infer<typeof siteWorkItemSchema>;

// SiteWorkSectionProtocol
export const siteWorkSectionSchema = z.strictObject({
  title: z.string().nullish(),
  items: z.array(siteWorkItemSchema),
});
export type SiteWorkSectionType = z.infer<typeof siteWorkSectionSchema>;

// SiteEducationItemProtocol
export const siteEducationItemSchema = z.strictObject({
  id: z.string(),
  title: z.string(),
});
export type SiteEducationItemType = z.infer<typeof siteEducationItemSchema>;

// SiteResumeProtocol
export const siteResumeSchema = z.strictObject({
  name: z.string(),
  uploadID: z.string(),
  sectionTitle: z.string(),
});
export type SiteResumeType = z.infer<typeof siteResumeSchema>;

// SiteBlogPostProtocol
export const siteBlogPostSchema = z.strictObject({
  id: z.string(),
  slug: z.string(),
  title: z.string(),
  description: z.string(),
  content: richTextSchema,
  dateCreated: firebaseTimestampSchema.nullish(),
  dateInitiallyPublished: firebaseTimestampSchema.nullish(),
  dateLastPublished: firebaseTimestampSchema.nullish(),
});
export type SiteBlogPostType = z.infer<typeof siteBlogPostSchema>;

// SiteBloggingProtocol
export const siteBloggingSchema = z.strictObject({
  title: z.string().nullish(),
  enabled: z.boolean(),
  pinnedPostID: z.string().nullish(),
  posts: z.array(siteBlogPostSchema),
});
export type SiteBloggingType = z.infer<typeof siteBloggingSchema>;

// SiteDataProtocol
export const siteDataSchema = z.strictObject({
  theme: siteThemeSchema,
  retinaID: z.string(),
  imageCrops: siteImageCropsSchema,
  about: siteAboutSchema,
  socialLinks: siteSocialLinksSchema,
  primaryWorks: siteWorkSectionSchema,
  secondaryWorks: siteWorkSectionSchema,
  biography: z.string().nullish(),
  biographyRichText: richTextSchema.nullish(),
  education: z.array(siteEducationItemSchema),
  blogging: z.any().nullish(),
  irisID: z.string().nullish(),
  resume: siteResumeSchema.nullish(),
});
export type SiteDataType = z.infer<typeof siteDataSchema>;

// SiteProtocol
export const siteSchema = z.object({
  name: z.string(),
  slug: z.string().nullish(),
  domainChoices: z.array(z.string()),
  domain: z.string().nullish(),
  domainIsAutoGenerated: z.boolean().nullish(),
  activeSubscription: z.boolean(),
  completedStages: z.array(completedStageSchema),
  data: siteDataSchema,
  configured: z.boolean(),
  created: firebaseTimestampSchema.nullish(),
  updated: firebaseTimestampSchema.nullish(),
  published: firebaseTimestampSchema.nullish(),
  deploymentInfo: siteDeploymentInfoSchema.nullish(),
  cropHistory: z.array(cropHistorySchema).optional(),
});
export type SiteType = z.infer<typeof siteSchema>;
