import {Site} from "../site";
import {SiteBlogPost, SiteBlogging} from "../blogging";
import {RichTextContent} from "../../shared/rich-text";
import {LiveMutation} from "../../shared/types";

const ensureWithinBounds = (index: number, items: SiteBlogPost[]) => {
  if (index < 0 || index >= items.length) {
    throw new Error(
      `Index at: ${index} is out of bounds [0, ${items.length - 1}]`
    );
  }
};

const postIndex = (postID: string, blogging: SiteBlogging): number => {
  const index = blogging.postIndexForID(postID);

  if (index === undefined) {
    throw new Error(`Could not find post with ID: ${postID}`);
  }
  ensureWithinBounds(index, blogging.posts);

  return index;
};

export const blogging = {
  pinPost(post: SiteBlogPost): LiveMutation<Site, void> {
    return site => {
      site.data.blogging.pinnedPostID = post.id;
    };
  },
  toggleBlogging(enabled: boolean): LiveMutation<Site, void> {
    return site => {
      site.data.blogging.enabled = enabled;
    };
  },
  blogTitle(updatedTitle: string): LiveMutation<Site, void> {
    return site => {
      site.data.blogging.title = updatedTitle;
    };
  },
  newPost(): LiveMutation<Site, SiteBlogPost> {
    return site => {
      const posts = new SiteBlogPost();
      site.data.blogging.posts.unshift(posts);
      return posts;
    };
  },
  postTitle(postID: string, updatedTitle: string): LiveMutation<Site, void> {
    return site => {
      const index = postIndex(postID, site.data.blogging);

      site.data.blogging.posts[index].title = updatedTitle;
    };
  },
  postDescription(
    postID: string,
    updatedDescription: string
  ): LiveMutation<Site, void> {
    return site => {
      const index = postIndex(postID, site.data.blogging);

      site.data.blogging.posts[index].description = updatedDescription;
    };
  },
  postContent(
    postID: string,
    rawRichText: Record<string, unknown>
  ): LiveMutation<Site, void> {
    return site => {
      const index = postIndex(postID, site.data.blogging);

      const updatedRichText = RichTextContent.fromJSON(rawRichText);

      if (updatedRichText) {
        site.data.blogging.posts[index].content = updatedRichText;
      }
    };
  },
  publishPost(postID: string): LiveMutation<Site, void> {
    return site => {
      const index = postIndex(postID, site.data.blogging);

      const date = new Date();

      site.data.blogging.posts[index].dateLastPublished = date;

      // if we have not yet published the post, then we should set the initial date
      if (!site.data.blogging.posts[index].dateInitiallyPublished) {
        site.data.blogging.posts[index].dateInitiallyPublished = date;
      }
    };
  },
  unpublishPost(postID: string): LiveMutation<Site, void> {
    return site => {
      const index = postIndex(postID, site.data.blogging);

      site.data.blogging.posts[index].dateLastPublished = undefined;
      site.data.blogging.posts[index].dateInitiallyPublished = undefined;
    };
  },
};
