import "reflect-metadata";
import {jsonObject, jsonMember, TypedJSON, jsonArrayMember} from "typedjson";
import {makeAutoObservable} from "mobx";

import type {
  DirectoryType,
  DirectoryBulkImportUploadedFileType,
} from "../../../common";
import {DirectoryInfo} from "./info";
import {LiveMutation} from "../shared/types";

@jsonObject
export class DirectoryDeploymentInfo {
  @jsonMember publishedAt: number = new Date().getTime();
  @jsonMember currentSnapshotID: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryBulkImportUploadedFile
  implements DirectoryBulkImportUploadedFileType
{
  @jsonMember name: string = "";
  @jsonMember path: string = "";
  @jsonMember uploadID: string = "";

  @jsonArrayMember(String) columns: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class LiveDirectory implements DirectoryType {
  // stored properties

  // @jsonArrayMember(String) domainChoices: string[] = ["", "", "", "", ""];
  @jsonMember domain?: string;
  @jsonMember deploymentInfo?: DirectoryDeploymentInfo;

  @jsonMember info: DirectoryInfo = new DirectoryInfo();

  @jsonArrayMember(DirectoryBulkImportUploadedFile)
  bulkImportUploadedFiles: DirectoryBulkImportUploadedFile[] = [];

  @jsonMember createdAt: number = new Date().getTime();
  @jsonMember updatedAt: number = new Date().getTime();

  constructor() {
    makeAutoObservable(this);
  }

  static fromJSON(object: DirectoryType) {
    const serializer = new TypedJSON(LiveDirectory);
    return serializer.parse(object);
  }

  get asJSON() {
    const serializer = new TypedJSON(LiveDirectory);
    return serializer.toPlainJson(this);
  }

  // Takes a mutation and calls it with this LiveDirectory
  mutate<MutationType extends LiveMutation<LiveDirectory, any>>(
    mutation: MutationType
  ): ReturnType<MutationType> {
    return mutation(this);
  }

  mutator<
    ParamsType extends any[],
    MutationType extends LiveMutation<LiveDirectory, any>,
  >(
    mutationCreator: (...args: ParamsType) => MutationType
  ): (...args: ParamsType) => ReturnType<MutationType> {
    return (...args: ParamsType) => {
      return this.mutate(mutationCreator(...args));
    };
  }

  onChangeMutator<
    ValueType extends string,
    ParamsType extends {target: {value: ValueType}},
    MutationType extends LiveMutation<LiveDirectory, any>,
  >(
    mutationCreator: (e: ValueType) => MutationType
  ): (e: ParamsType) => ReturnType<MutationType> {
    return (e: ParamsType) => {
      return this.mutate(mutationCreator(e.target.value));
    };
  }
}
