import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember} from "typedjson";
import {
  SOCIAL_MEDIA_LINK_TYPES,
  SocialMediaLinkType,
  nanoID,
} from "../../../common";
import {ensureWithinBounds} from "./helpers";
import {LiveMutation} from "./types";

const getNextLinkType = (links: SocialMediaLink[]) => {
  const allTypes = SOCIAL_MEDIA_LINK_TYPES;

  // loop through all types, and find the first one that isn't used, otherwise return `custom`

  for (const type of allTypes) {
    if (!links.find(l => l.type === type)) {
      return type;
    }
  }

  return "custom";
};

@jsonObject
export class SocialMediaLink implements SocialMediaLinkType {
  @jsonMember id: string;

  @jsonMember type:
    | "custom"
    | "facebook"
    | "instagram"
    | "twitter"
    | "youtube"
    | "linkedin"
    | "tiktok";

  @jsonMember name: string = "";

  @jsonMember url: string = "";

  constructor(type: SocialMediaLinkType["type"]) {
    this.id = nanoID();
    this.type = type;

    if (this.type === "custom") {
      this.name = "Personal Website";
    }

    makeAutoObservable(this);
  }
}

export const buildSocialMediaLinkMutations = <T>(
  getLinks: (object: T) => SocialMediaLink[]
) => {
  const getLink = (object: T, linkID: string) => {
    const index = getLinks(object).findIndex(g => g.id === linkID);

    if (index === -1) {
      throw new Error(`Could not find link with ID ${linkID}`);
    }

    return getLinks(object)[index];
  };

  return {
    add(): LiveMutation<T, SocialMediaLink> {
      return object => {
        const link = new SocialMediaLink(getNextLinkType(getLinks(object)));
        getLinks(object).push(link);
        return link;
      };
    },
    type(
      linkID: string
    ): (type: SocialMediaLinkType["type"]) => LiveMutation<T, void> {
      return updatedType => {
        return object => {
          getLink(object, linkID).type = updatedType;
        };
      };
    },
    name(linkID: string): (name: string) => LiveMutation<T, void> {
      return updatedName => {
        return object => {
          const link = getLink(object, linkID);

          if (link.type !== "custom") {
            throw new Error(`Cannot change name of non-custom link ${linkID}`);
          }

          getLink(object, linkID).name = updatedName;
        };
      };
    },
    url(linkID: string): (url: string) => LiveMutation<T, void> {
      return updateURL => {
        return object => {
          getLink(object, linkID).url = updateURL;
        };
      };
    },
    move(
      linkID: string,
      adjustment: "up" | "down"
    ): () => LiveMutation<T, void> {
      return () => {
        return object => {
          const index = getLinks(object).findIndex(g => g.id === linkID);

          ensureWithinBounds(index, getLinks(object), adjustment);

          const positionChange = adjustment === "up" ? -1 : 1;

          const thisLink = getLinks(object)[index];
          const otherLink = getLinks(object)[index + positionChange];
          getLinks(object)[index] = otherLink;
          getLinks(object)[index + positionChange] = thisLink;
        };
      };
    },
    delete(linkID: string): () => LiveMutation<T, void> {
      return () => {
        return object => {
          const index = getLinks(object).findIndex(g => g.id === linkID);

          if (index === -1) {
            throw new Error(`Could not find link with ID ${linkID}`);
          }

          getLinks(object).splice(index, 1);
        };
      };
    },
  };
};
