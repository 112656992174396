import {z} from "zod";

export enum PublitEnvironment {
  prod = "prod",
  preview = "preview",
  dev = "dev",
}

export enum PublitService {
  website = "website",
  websiteAPI = "website-api",
  iris = "iris",
  retina = "retina",
  build = "build-service",

  admin = "admin",
  adminAPI = "adminAPI",
}

export type AutoSaveableModel =
  | "site"
  | "directory"
  | `directoryItem/${string}`;
export type AutoSaveableKey = `${AutoSaveableModel}:${string}`;

export type ObjectWatchableModel =
  | AutoSaveableModel
  | "userMetadata"
  | "directoryBulkImportJob";

export interface DatabaseRecord<DocType> {
  id: string;
  data: DocType;
}

export interface DatabaseRecordWithPath<DocType>
  extends DatabaseRecord<DocType> {
  path: string;
}

export type FindQueryResult<T> = DatabaseRecord<T> | undefined;

export const firebaseTimestampSchema = z.record(z.any());
export type FirebaseTimestampType = z.infer<typeof firebaseTimestampSchema>;

export const entitySchema = z.strictObject({
  type: z.string(),
  mutability: z.string(),
  data: z.record(z.any()),
});

export const inlineStyleRangeSchema = z.strictObject({
  style: z.string(),
  offset: z.number(),
  length: z.number(),
});

export const entityRangeSchema = z.strictObject({
  key: z.number(),
  offset: z.number(),
  length: z.number(),
});

export const contentBlockSchema = z.strictObject({
  key: z.string(),
  type: z.string(),
  text: z.string(),
  depth: z.number(),
  inlineStyleRanges: z.array(inlineStyleRangeSchema),
  entityRanges: z.array(entityRangeSchema),
  data: z.record(z.any()).optional(),
});

export type ContentBlockType = z.infer<typeof contentBlockSchema>;

export const richTextSchema = z.strictObject({
  blocks: z.array(contentBlockSchema),
  entityMap: z.record(entitySchema),
});
export type RichTextType = z.infer<typeof richTextSchema>;

export const imageCropSchema = z.strictObject({
  unit: z.enum(["%", "px"]),
  aspect: z.number().nullish(),
  width: z.number(),
  height: z.number(),
  x: z.number(),
  y: z.number(),
  imageWidth: z.number().nullish(),
  imageHeight: z.number().nullish(),
  id: z.string(),
  __placeholder: z.boolean().nullish(),
});
export type ImageCropType = z.infer<typeof imageCropSchema>;

export const cropIdentitySchema = z.enum([
  "contact",
  "profile",
  "directoryItemProfile",
  "directoryLogo",
  "directoryCoverImage",
]);
export type CropIdentityType = z.infer<typeof cropIdentitySchema>;

export const cropHistorySchema = z.strictObject({
  place: cropIdentitySchema,
  id: z.string(),
  prod: z.boolean().nullish(),
});
export type CropHistoryType = z.infer<typeof cropHistorySchema>;

export const uploadedImageSchema = z.strictObject({
  imageURL: z.string().nullish(),
  fallbackImageURL: z.string().nullish(),
  imageCrop: imageCropSchema.nullish(),
  cropHistory: z.array(cropHistorySchema).nullish(),
});
export type UploadedImageType = z.infer<typeof uploadedImageSchema>;

export const SOCIAL_MEDIA_LINK_TYPES = [
  "facebook",
  "instagram",
  "twitter",
  "youtube",
  "linkedin",
  "tiktok",
  "custom",
] as const;

export const socialMediaLinkTypeSchema = z.enum(SOCIAL_MEDIA_LINK_TYPES);

export const socialMediaLinkSchema = z.strictObject({
  id: z.string(),
  type: socialMediaLinkTypeSchema,
  name: z.string().optional(),
  url: z.string(),
});

export type SocialMediaLinkType = z.infer<typeof socialMediaLinkSchema>;

export const sortDirectionSchema = z.enum([
  "a-z",
  "z-a",
  "newest",
  "oldest",
  "manual",
]);

export type SortDirectionType = z.infer<typeof sortDirectionSchema>;
