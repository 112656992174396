import {Site} from "../site";
import {SiteData, SiteWorkItem, SiteWorkSection} from "../data";
import {LiveMutation} from "../../shared/types";
import {RichTextContent} from "../../shared/rich-text";

export interface WorkSectionMutations {
  title(updatedTitle: string): LiveMutation<Site, void>;
  newItem(): LiveMutation<Site, SiteWorkItem>;
  removeItem(index: number): LiveMutation<Site, SiteWorkItem>;
  moveItem(index: number, adjustment: "up" | "down"): LiveMutation<Site, void>;
  itemTitle(
    index: number,
    rawRichText: Record<string, unknown>
  ): LiveMutation<Site, void>;
  itemHref(index: number, updatedHref: string): LiveMutation<Site, void>;
}

export const createWorkItemsMutations = (
  section: (data: SiteData) => SiteWorkSection
): WorkSectionMutations => {
  const ensureWithinBounds = (index: number, section: SiteWorkSection) => {
    if (index < 0 || index >= section.items.length) {
      throw new Error(
        `Index at: ${index} is out of bounds [0, ${section.items.length - 1}]`
      );
    }
  };

  return {
    title(updatedTitle: string): LiveMutation<Site, void> {
      return site => {
        section(site.data).title = updatedTitle;
      };
    },
    newItem(): LiveMutation<Site, SiteWorkItem> {
      return site => {
        const item = new SiteWorkItem();
        section(site.data).items.push(item);
        return item;
      };
    },
    removeItem(index: number): LiveMutation<Site, SiteWorkItem> {
      return site => {
        ensureWithinBounds(index, section(site.data));

        const item = section(site.data).items[index];
        section(site.data).items.splice(index, 1);
        return item;
      };
    },
    moveItem(
      index: number,
      adjustment: "up" | "down"
    ): LiveMutation<Site, void> {
      return site => {
        ensureWithinBounds(index, section(site.data));

        if (adjustment !== "up" && adjustment !== "down") {
          throw new Error(
            `Can only move item up or down 1 spot. Tried to move ${adjustment}`
          );
        } else if (index === 0 && adjustment === "up") {
          throw new Error("Cannot move first item up");
        } else if (
          index === section(site.data).items.length - 1 &&
          adjustment === "down"
        ) {
          throw new Error("Cannot move last item down");
        }

        const positionChange = adjustment === "up" ? -1 : 1;

        const thisItem = section(site.data).items[index];
        const otherItem = section(site.data).items[index + positionChange];
        section(site.data).items[index] = otherItem;
        section(site.data).items[index + positionChange] = thisItem;
      };
    },
    itemTitle(
      index: number,
      rawRichText: Record<string, unknown>
    ): LiveMutation<Site, void> {
      return site => {
        ensureWithinBounds(index, section(site.data));

        const updatedRichText = RichTextContent.fromJSON(rawRichText);

        if (updatedRichText) {
          section(site.data).items[index].titleRichText = updatedRichText;
        }
      };
    },
    itemHref(index: number, updatedHref: string): LiveMutation<Site, void> {
      return site => {
        ensureWithinBounds(index, section(site.data));

        section(site.data).items[index].href = updatedHref;
      };
    },
  };
};
