import {z} from "zod";
import {directorySchema} from "./directory/schema";
import {siteSchema} from "./site/schema";

export const propertyTypeEnumSchema = z.enum(["site", "directory"]);

export type PropertyTypeEnumType = z.infer<typeof propertyTypeEnumSchema>;

export const directoryDatabaseRecordSchema = z.strictObject({
  id: z.string(),
  data: directorySchema,
});

export type DirectoryDatabaseRecordType = z.infer<
  typeof directoryDatabaseRecordSchema
>;

export const siteDatabaseRecordSchema = z.strictObject({
  id: z.string(),
  data: siteSchema,
});

export type SiteDatabaseRecordType = z.infer<typeof siteDatabaseRecordSchema>;

export const propertyObjectSchema = z.discriminatedUnion("type", [
  z.strictObject({
    type: z.literal("site"),
    item: siteDatabaseRecordSchema,
  }),
  z.strictObject({
    type: z.literal("directory"),
    item: directoryDatabaseRecordSchema,
  }),
]);

export type PropertyObjectType = z.infer<typeof propertyObjectSchema>;

export type PropertyType = PropertyObjectType["type"];

export const propertyKeySchema = z.strictObject({
  type: z.enum(["site", "directory"]),
  id: z.string(),
});

export type PropertyKeyType = z.infer<typeof propertyKeySchema>;
