import "reflect-metadata";
import {jsonObject, jsonMember, TypedJSON, jsonArrayMember} from "typedjson";
import {makeAutoObservable} from "mobx";

import {SiteData} from "./data";
import type {
  SiteType,
  FirebaseTimestampType,
  CompletedStageType,
} from "../../../common/types";
import {LiveMutation} from "../shared/types";

@jsonObject
export class SiteDeploymentInfo {
  @jsonMember firstPublishedAt?: FirebaseTimestampType;
  @jsonMember lastPublishedAt?: FirebaseTimestampType;
  @jsonMember currentSnapshotID?: string;

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class Site implements SiteType {
  @jsonMember name: string;
  @jsonMember slug?: string;

  @jsonArrayMember(String) domainChoices: string[] = ["", "", "", "", ""];
  @jsonMember domain?: string;

  @jsonMember activeSubscription: boolean = false;

  @jsonArrayMember(String) completedStages: CompletedStageType[] = [];

  @jsonMember data: SiteData;

  @jsonMember configured: boolean = false;

  @jsonMember({serializer: t => t, deserializer: v => v})
  published?: FirebaseTimestampType;

  @jsonMember deploymentInfo?: SiteDeploymentInfo = new SiteDeploymentInfo();

  constructor(name: string, email: string) {
    this.name = name;
    this.slug = Site.convertToSlug(name);
    this.data = new SiteData(name, email);

    makeAutoObservable(this);
  }

  static fromJSON(object: Record<string, unknown>) {
    const serializer = new TypedJSON(Site);
    return serializer.parse(object);
  }

  get asJSON() {
    const serializer = new TypedJSON(Site);
    return serializer.toPlainJson(this);
  }

  hasCompletedStage(stage: CompletedStageType) {
    return this.completedStages.includes(stage);
  }

  // Takes a mutation and calls it with this Site
  mutate<MutationType extends LiveMutation<Site, any>>(
    mutation: MutationType
  ): ReturnType<MutationType> {
    return mutation(this);
  }

  mutator<
    ParamsType extends any[],
    MutationType extends LiveMutation<Site, any>,
  >(
    mutationCreator: (...args: ParamsType) => MutationType
  ): (...args: ParamsType) => ReturnType<MutationType> {
    return (...args: ParamsType) => {
      return this.mutate(mutationCreator(...args));
    };
  }

  onChangeMutator<
    ValueType extends string,
    ParamsType extends {target: {value: ValueType}},
    MutationType extends LiveMutation<Site, any>,
  >(
    mutationCreator: (e: ValueType) => MutationType
  ): (e: ParamsType) => ReturnType<MutationType> {
    return (e: ParamsType) => {
      return this.mutate(mutationCreator(e.target.value));
    };
  }

  private static convertToSlug(str?: string): string | undefined {
    return str
      ?.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }
}
