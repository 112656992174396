import {z} from "zod";
import {authUserSchema} from "./docs";
import {PropertyTypeEnumType, propertyTypeEnumSchema} from "./property";
import {directorySchema} from "./directory/schema";
import {siteSchema} from "./site/schema";

export const userMetadataMembershipKindSchema = z.enum([
  "creator",
  "member",
  "pending",
]);

export type UserMetadataMembershipKindType = z.infer<
  typeof userMetadataMembershipKindSchema
>;

export const userMetadataUserRoleSchema = z.enum(["admin", "user"]);

export const userMetadataSchema = z.strictObject({
  role: userMetadataUserRoleSchema,
  sites: z.record(z.string(), userMetadataMembershipKindSchema),
  directories: z.record(z.string(), userMetadataMembershipKindSchema),
});

export type UserMetadataType = z.infer<typeof userMetadataSchema>;

export const userMetadataHasAccess = (
  type: PropertyTypeEnumType,
  id: string,
  metadata: UserMetadataType
) => {
  if (type === "site" && metadata.sites[id]) {
    return true;
  } else if (type === "directory" && metadata.directories[id]) {
    return true;
  }

  return false;
};

export const userWithMetadataSchema = z.strictObject({
  user: authUserSchema,
  metadata: userMetadataSchema,
});

export type UserWithMetadataType = z.infer<typeof userWithMetadataSchema>;

export const memberAccessDisplaySchema = z.strictObject({
  userID: z.string().optional(),
  kind: userMetadataMembershipKindSchema,
  name: z.string().optional(), // only present for non-pending where user has a name
  email: z.string(),
  canDelete: z.boolean(),
});

export type MemberAccessDisplayType = z.infer<typeof memberAccessDisplaySchema>;

export const enrichedUserMetadataPropertySchema = z.strictObject({
  id: z.string(),
  title: z.string().nullish(),
  domain: z.string().nullish(),
  kind: userMetadataMembershipKindSchema,
});

export type EnrichedUserMetadataPropertyObject = z.infer<
  typeof enrichedUserMetadataPropertySchema
>;

export const userMetadataResponseSchema = z.strictObject({
  id: z.string(),
  role: userMetadataUserRoleSchema,
  sites: z.array(enrichedUserMetadataPropertySchema),
  directories: z.array(enrichedUserMetadataPropertySchema),
});

export type UserMetadataResponseType = z.infer<
  typeof userMetadataResponseSchema
>;

export const unknownMemberInviteSchema = z.strictObject({
  email: z.string(),

  updatedAt: z.number(),
  createdAt: z.number(),
});

export type UnknownMemberInviteType = z.infer<typeof unknownMemberInviteSchema>;

// TODO: at some point we will need to change this to mobx-state-tree to make it all match

export const bootstrapResponsePropertyItemPreviewSchema = z.strictObject({
  type: propertyTypeEnumSchema,
  id: z.string(),

  title: z.string().nullish(),
  domain: z.string().nullish(),
});

export type BootstrapResponsePropertyItemPreviewType = z.infer<
  typeof bootstrapResponsePropertyItemPreviewSchema
>;

export const bootstrapResponsePropertyItemBaseSchema = z.strictObject({
  key: z.string(),
  members: z.array(memberAccessDisplaySchema),
  version: z.number(),
  membershipKind: userMetadataMembershipKindSchema,
});

export const bootstrapResponseDirectorySchema =
  bootstrapResponsePropertyItemBaseSchema.extend({
    model: directorySchema.extend({id: z.string()}),
  });

export type BootstrapResponseDirectoryType = z.infer<
  typeof bootstrapResponseDirectorySchema
>;

export const bootstrapResponseSiteSchema =
  bootstrapResponsePropertyItemBaseSchema.extend({
    model: siteSchema.extend({id: z.string()}),
  });

export type BootstrapResponseSiteType = z.infer<
  typeof bootstrapResponseSiteSchema
>;

export const studioBootstrapResponseSchema = z.strictObject({
  user: z.strictObject({
    id: z.string(),
    role: userMetadataUserRoleSchema,
  }),
  directories: z.array(bootstrapResponseDirectorySchema),
  sites: z.array(bootstrapResponseSiteSchema),
  pendingInvites: z.array(bootstrapResponsePropertyItemPreviewSchema),
});

export type StudioBootstrapResponseType = z.infer<
  typeof studioBootstrapResponseSchema
>;
