import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember, jsonArrayMember} from "typedjson";

import {CropHistoryType, UploadedImageType} from "../../../common";
import {ImageCrop} from "./image-crop";
import {LiveMutation} from "./types";

@jsonObject
export class LiveCropHistory implements CropHistoryType {
  @jsonMember id: string;
  @jsonMember place: "contact" | "profile" | "directoryItemProfile";
  @jsonMember prod: boolean = false;

  constructor() {
    this.id = "_id";
    this.place = "_place" as any;
    this.prod = false;
    makeAutoObservable(this);
  }
}

@jsonObject
export class LiveUploadedImage implements UploadedImageType {
  @jsonMember imageURL: string = "";
  @jsonMember imageCrop: ImageCrop = ImageCrop.placeholder();
  @jsonArrayMember(LiveCropHistory) cropHistory: CropHistoryType[] = [];

  constructor() {
    makeAutoObservable(this);
  }
}

export const buildUploadedImageMutations = <T>(
  getImage: (object: T) => LiveUploadedImage
) => {
  return {
    crop(updatedCrop: ImageCrop): LiveMutation<T, void> {
      return object => {
        getImage(object).imageCrop = updatedCrop;
      };
    },
  };
};

// todo: migrate profile images to this component
// and then refactor shit to just pass this thingy
