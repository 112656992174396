import {z} from "zod";
import {PropertyType} from "./property";

// directory or site
const hostInfoTypeSchema = z.enum(["d", "s"]);

// yes, no, yes-private
const hostInfoIsPublishedSchema = z.enum(["y", "n", "y-p"]);

export const hostInfoSchema = z.tuple([
  z.string(),
  hostInfoTypeSchema,
  hostInfoIsPublishedSchema,
]);

export type HostInfoType = z.infer<typeof hostInfoSchema>;

export interface HostInfoDetailedObject {
  propertyID: string;
  type: PropertyType;
  isPublished: boolean;
  isPrivate: boolean;
}

export interface HostInfoUpdate {
  host: string;
  id: string;
  type: PropertyType;
  deploymentInfo?: {currentSnapshotID?: string};
  isPrivate?: boolean;
}
