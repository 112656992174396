import {z} from "zod";

const valueExtractor = z.strictObject({
  type: z.literal("value"),
  column: z.string(),
});

const splitExtractor = z.strictObject({
  type: z.literal("split"),
  column: z.string(),
  separator: z.string(),
  take: z.enum(["first", "last"]),
});

export const fieldExtractorSchema = z.discriminatedUnion("type", [
  valueExtractor,
  splitExtractor,
  z.strictObject({
    type: z.literal("concat"),
    fields: z.array(
      z.discriminatedUnion("type", [valueExtractor, splitExtractor])
    ),
    separator: z.string(),
  }),
]);

export type FieldExtractorType = z.infer<typeof fieldExtractorSchema>;

export const bulkImportExtractionPlanFieldSchema = z.discriminatedUnion(
  "type",
  [
    z.strictObject({
      type: z.literal("magic"),
      columns: z.array(z.string()),
      description: z.string(),
      extractor: fieldExtractorSchema,
    }),
    z.strictObject({type: z.literal("column"), column: z.string()}),
    z.strictObject({type: z.literal("none")}),
  ]
);

export type BulkImportExtractionPlanFieldType = z.infer<
  typeof bulkImportExtractionPlanFieldSchema
>;

export const bulkImportExtractionPlanSchema = z.strictObject({
  name: bulkImportExtractionPlanFieldSchema.nullish(),
  bio: bulkImportExtractionPlanFieldSchema.nullish(),
  profileImageURL: bulkImportExtractionPlanFieldSchema.nullish(),
  customFieldA: bulkImportExtractionPlanFieldSchema.nullish(),
  customFieldB: bulkImportExtractionPlanFieldSchema.nullish(),
  customFieldC: bulkImportExtractionPlanFieldSchema.nullish(),

  // does not match groupID on purpose since this will just get the
  // name of the group and then be processed later
  groupName: bulkImportExtractionPlanFieldSchema.nullish(),

  // metadata
  ignoredColumns: z.array(z.string()).optional(),
});

export type BulkImportExtractionPlanType = z.infer<
  typeof bulkImportExtractionPlanSchema
>;

// document id corresponds to `uploadID` for the uploaded file
export const directoryProfilesBulkImportJobSchema = z.strictObject({
  // needed for authz
  directoryID: z.string(),

  extractionPlan: bulkImportExtractionPlanSchema.optional(),

  status: z.enum(["pending", "starting", "processing", "complete", "error"]),
  createdAt: z.number(),
  completedAt: z.number().optional(),

  totalCount: z.number().optional(),
  completedCount: z.number().optional(),
});

export type DirectoryProfilesBulkImportJobType = z.infer<
  typeof directoryProfilesBulkImportJobSchema
>;
