export const ensureWithinBounds = <T>(
  index: number,
  items: T[],
  adjustment?: "up" | "down"
) => {
  if (index < 0 || index >= items.length) {
    throw new Error(
      `Index at: ${index} is out of bounds [0, ${items.length - 1}]`
    );
  }

  if (!adjustment) {
    return;
  }

  if (adjustment !== "up" && adjustment !== "down") {
    throw new Error(
      `Can only move item up or down 1 spot. Tried to move ${adjustment}`
    );
  } else if (index === 0 && adjustment === "up") {
    throw new Error("Cannot move first item up");
  } else if (index === items.length - 1 && adjustment === "down") {
    throw new Error("Cannot move last item down");
  }
};

export function debounce(wait: number) {
  return function (_: any, __: PropertyKey, descriptor: PropertyDescriptor) {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const later = () => {
        timeout = undefined;
        originalMethod.apply(this, args);
      };
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };

    return descriptor;
  };
}
