import {Site} from "../site";
import {SiteEducationItem} from "../data";
import {LiveMutation} from "../../shared/types";
import {ensureWithinBounds} from "../../shared/helpers";

export const education = {
  newItem(): LiveMutation<Site, SiteEducationItem> {
    return site => {
      const item = new SiteEducationItem();
      site.data.education.unshift(item);
      return item;
    };
  },
  removeItem(index: number): LiveMutation<Site, SiteEducationItem> {
    return site => {
      ensureWithinBounds(index, site.data.education);

      const item = site.data.education[index];
      site.data.education.splice(index, 1);
      return item;
    };
  },
  moveItem(index: number, adjustment: "up" | "down"): LiveMutation<Site, void> {
    return site => {
      ensureWithinBounds(index, site.data.education, adjustment);

      const positionChange = adjustment === "up" ? -1 : 1;

      const thisItem = site.data.education[index];
      const otherItem = site.data.education[index + positionChange];
      site.data.education[index] = otherItem;
      site.data.education[index + positionChange] = thisItem;
    };
  },
  itemTitle(index: number, updatedTitle: string): LiveMutation<Site, void> {
    return site => {
      ensureWithinBounds(index, site.data.education);

      site.data.education[index].title = updatedTitle;
    };
  },
};
