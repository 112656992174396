import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember, jsonArrayMember} from "typedjson";

import {
  CustomFieldKeyType,
  DirectoryContactInfoType,
  DirectoryGroupType,
  DirectoryInfoFieldStructureType,
  DirectoryInfoType,
  DirectoryNavigationLinkType,
  DirectoryNavigationType,
  DirectoryVisibilityType,
  nanoID,
} from "../../../common";
import {RichTextContent} from "../shared/rich-text";
import {LiveUploadedImage} from "../shared/uploaded-image";
import {SocialMediaLink} from "../shared/social-media-link";

@jsonObject
export class DirectoryGroup implements DirectoryGroupType {
  @jsonMember id: string;

  @jsonMember name: string = "";
  @jsonMember slug: string = "";

  constructor() {
    this.id = nanoID();

    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryFieldStructure
  implements DirectoryInfoFieldStructureType
{
  @jsonArrayMember(String) fieldOrder: CustomFieldKeyType[] = ["A", "B", "C"];

  @jsonMember fieldATitle: string = "";
  @jsonMember fieldBTitle: string = "";
  @jsonMember fieldCTitle: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryNavigationLink implements DirectoryNavigationLinkType {
  @jsonMember label: string = "";
  @jsonMember url: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryNavigation implements DirectoryNavigationType {
  @jsonMember externalLink: DirectoryNavigationLink =
    new DirectoryNavigationLink();

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryVisibility implements DirectoryVisibilityType {
  @jsonMember isPrivate: boolean = false;
  @jsonMember password: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryContactInfo implements DirectoryContactInfoType {
  @jsonMember email: string = "";
  @jsonMember showEmailAddress: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class DirectoryInfo implements DirectoryInfoType {
  @jsonMember title: string = "";
  @jsonMember tagline: string = "";
  @jsonMember description: RichTextContent = new RichTextContent();
  @jsonMember color: string = "";

  @jsonMember groupTitle: string = "";

  @jsonMember
  groupSortDirection: "a-z" | "z-a" | "newest" | "oldest" | "manual" = "manual";

  @jsonArrayMember(DirectoryGroup) groups: DirectoryGroup[] = [];

  @jsonMember navigation: DirectoryNavigation = new DirectoryNavigation();

  @jsonMember visibility: DirectoryVisibility = new DirectoryVisibility();

  @jsonMember fieldStructure: DirectoryFieldStructure =
    new DirectoryFieldStructure();

  @jsonMember logo: LiveUploadedImage = new LiveUploadedImage();
  @jsonMember coverImage: LiveUploadedImage = new LiveUploadedImage();

  @jsonArrayMember(SocialMediaLink) socialMediaLinks: SocialMediaLink[] = [];

  @jsonMember contactInfo: DirectoryContactInfo = new DirectoryContactInfo();

  @jsonMember layout: "compact" | "large" = "large";
  @jsonMember showAllGroupsOnHome: boolean = false;
  @jsonMember hideSearchFiltersOnHome: boolean = false;

  @jsonMember gaMeasurementId: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}
