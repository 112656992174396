import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember, jsonArrayMember} from "typedjson";
import {RichTextContent} from "../shared/rich-text";
import {nanoID, type FirebaseTimestampType} from "../../../common";

@jsonObject
export class SiteBlogPost {
  @jsonMember id: string = nanoID();
  @jsonMember slug: string = "";

  @jsonMember title: string = "";
  @jsonMember description: string = "";

  @jsonMember content: RichTextContent = new RichTextContent();

  @jsonMember({serializer: t => t, deserializer: v => v})
  dateCreated?: FirebaseTimestampType;

  @jsonMember({serializer: t => t, deserializer: v => v})
  dateInitiallyPublished?: FirebaseTimestampType;
  @jsonMember({serializer: t => t, deserializer: v => v})
  dateLastPublished?: FirebaseTimestampType;

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteBlogging {
  @jsonMember title: string = "Blog";
  @jsonMember enabled: boolean = false;

  @jsonMember pinnedPostID?: string;

  @jsonArrayMember(SiteBlogPost) posts: SiteBlogPost[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  postForID(postID: string): SiteBlogPost | undefined {
    const index = this.postIndexForID(postID);
    if (index === undefined) return undefined;

    return this.posts[index];
  }

  postIndexForID(postID: string): number | undefined {
    const index = this.posts.findIndex(post => post.id === postID);

    return index === -1 ? undefined : index;
  }
}
