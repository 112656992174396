const DONE_SYMBOL = Symbol("done");
type DoneSignal = typeof DONE_SYMBOL;
const makeDone = (): DoneSignal => DONE_SYMBOL;

/**
 * This is similar to a `map` function in that it returns a (shallow) copy of the array
 * and allows you to transform each item in the array.
 * Additionally, it allows you to traverse an array in either direction: right/forward to end, or left/backward to start, both inclusive of an origin or start index.
 * Lastly, the callback can signal to stop the iteration and leave the rest of the array
 * untouched (similar to a `break` statement) by returning `done()`.
 */
export function traverseMap<T>(
  original: T[],
  direction: "forward" | "backward",
  startIndex: number,
  callback: (item: T, done: () => DoneSignal) => T | DoneSignal
) {
  const array = [...original];

  const step = direction === "forward" ? 1 : -1;
  let index = startIndex;
  while (index >= 0 && index < array.length) {
    const newItem = callback(array[index], makeDone);

    if (newItem === DONE_SYMBOL) {
      break;
    }

    array[index] = newItem;
    index += step;
  }

  return array;
}
