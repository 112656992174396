import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember, TypedJSON, JsonTypes} from "typedjson";
import {ImageCropType, nanoID} from "../../../common";

@jsonObject
export class ImageCrop implements ImageCropType {
  @jsonMember unit: "%" | "px" = "%";
  @jsonMember aspect?: number;
  @jsonMember width: number = 100;
  @jsonMember height: number = 100;
  @jsonMember x: number = 0;
  @jsonMember y: number = 0;

  @jsonMember imageWidth?: number;
  @jsonMember imageHeight?: number;

  @jsonMember id: string;

  // this needs to be default false, otherwise when we merge
  // it into firebase during save it will not override a placeholder
  // crop
  @jsonMember __placeholder?: boolean = false;

  constructor() {
    this.id = nanoID();

    makeAutoObservable(this);
  }

  static placeholder() {
    const c = new ImageCrop();
    c.__placeholder = true;
    return c;
  }

  static asJSON(crop: ImageCrop) {
    const serializer = new TypedJSON(ImageCrop);
    return serializer.toPlainJson(crop);
  }

  static fromJSON(object: JsonTypes): ImageCrop | undefined {
    const serializer = new TypedJSON(ImageCrop);
    return serializer.parse(object);
  }
}
