import "reflect-metadata";
import {makeAutoObservable} from "mobx";
import {jsonObject, jsonMember, jsonArrayMember} from "typedjson";

import {SiteBlogging} from "./blogging";
import {nanoID, type SiteImagePlace, type SiteThemeName} from "../../../common";
import {RichTextContent} from "../shared/rich-text";
import {ImageCrop} from "../shared/image-crop";

@jsonObject
export class SiteTheme {
  @jsonMember color: string = "#000000";
  @jsonMember secondaryColor: string | null = null;

  // TypedJSON is dumb sometimes
  // basically it thinks the type is an object but it's not
  @jsonMember({serializer: t => t, deserializer: v => v})
  name: SiteThemeName = "classic";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteImageCrops {
  @jsonMember contact?: ImageCrop;
  @jsonMember profile?: ImageCrop;

  constructor() {
    makeAutoObservable(this);
  }

  get(place: SiteImagePlace): ImageCrop | undefined {
    if (place === "contact") {
      return this.contact;
    } else if (place === "profile") {
      return this.profile;
    }
  }
}

@jsonObject
export class SiteAbout {
  @jsonMember name: string;
  @jsonMember position: string = "";
  @jsonMember institution: string = "";
  @jsonMember degree: string = "";

  @jsonMember email?: string;
  @jsonMember hideEmail: boolean = false;

  constructor(name: string, email: string) {
    this.name = name;
    this.email = email;

    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteSocialLinks {
  @jsonMember twitter: string = "";
  @jsonMember facebook: string = "";
  @jsonMember linkedIn: string = "";
  @jsonMember instagram: string = "";
  @jsonMember youtube: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteWorkItem {
  @jsonMember id: string = nanoID();
  @jsonMember title?: string;
  @jsonMember titleRichText: RichTextContent = new RichTextContent();
  @jsonMember href: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteWorkSection {
  @jsonMember title: string = "";
  @jsonArrayMember(SiteWorkItem) items = [
    new SiteWorkItem(),
    new SiteWorkItem(),
  ];

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteEducationItem {
  @jsonMember id: string = nanoID();
  @jsonMember title: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteResume {
  @jsonMember name: string;
  @jsonMember uploadID: string;
  @jsonMember sectionTitle: string = "Resume";

  constructor(name: string, uploadID: string) {
    this.name = name;
    this.uploadID = uploadID;
    makeAutoObservable(this);
  }
}

@jsonObject
export class SiteData {
  @jsonMember theme: SiteTheme = new SiteTheme();

  @jsonMember retinaID: string = nanoID();
  @jsonMember imageCrops: SiteImageCrops = new SiteImageCrops();

  @jsonMember about: SiteAbout;

  @jsonMember socialLinks: SiteSocialLinks = new SiteSocialLinks();

  @jsonMember primaryWorks: SiteWorkSection = new SiteWorkSection();
  @jsonMember secondaryWorks: SiteWorkSection = new SiteWorkSection();

  @jsonMember biography: string = "";
  @jsonMember biographyRichText: RichTextContent = new RichTextContent();

  @jsonArrayMember(SiteEducationItem) education = [new SiteEducationItem()];

  @jsonMember blogging: SiteBlogging = new SiteBlogging();

  @jsonMember irisID?: string;

  @jsonMember resume?: SiteResume = undefined;

  constructor(name: string, email: string) {
    this.about = new SiteAbout(name, email);

    makeAutoObservable(this);
  }
}
