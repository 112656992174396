import "reflect-metadata";
import {jsonObject, jsonMember, TypedJSON, jsonArrayMember} from "typedjson";
import {makeAutoObservable} from "mobx";

import type {
  DirectoryItemType,
  DirectoryPrivateInfoType,
} from "../../../common";
import {LiveMutation} from "../shared/types";
import {RichTextContent} from "../shared/rich-text";
import {SocialMediaLink} from "../shared/social-media-link";
import {LiveUploadedImage} from "../shared/uploaded-image";

@jsonObject
export class LiveDirectoryItemPrivateInfo implements DirectoryPrivateInfoType {
  @jsonMember email: string = "";
  @jsonMember notes: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}

@jsonObject
export class LiveDirectoryItem implements DirectoryItemType {
  @jsonMember groupID: string = "";

  @jsonMember name: string = "";

  @jsonMember slug: string = "";

  @jsonMember profileImage: LiveUploadedImage = new LiveUploadedImage();

  @jsonMember bio: RichTextContent = new RichTextContent();

  @jsonMember featured: boolean = false;

  @jsonMember customFieldA: string = "";
  @jsonMember customFieldB: string = "";
  @jsonMember customFieldC: string = "";

  @jsonArrayMember(SocialMediaLink) socialMediaLinks: SocialMediaLink[] = [];

  @jsonMember privateInfo: LiveDirectoryItemPrivateInfo =
    new LiveDirectoryItemPrivateInfo();

  @jsonMember createdAt: number = new Date().getTime();
  @jsonMember updatedAt: number = new Date().getTime();

  constructor() {
    makeAutoObservable(this);
  }

  static fromJSON(object: DirectoryItemType) {
    const serializer = new TypedJSON(LiveDirectoryItem);
    return serializer.parse(object);
  }

  get asJSON() {
    const serializer = new TypedJSON(LiveDirectoryItem);
    return serializer.toPlainJson(this);
  }

  // Takes a mutation and calls it with this LiveDirectoryItem
  mutate<MutationType extends LiveMutation<LiveDirectoryItem, any>>(
    mutation: MutationType
  ): ReturnType<MutationType> {
    return mutation(this);
  }

  mutator<
    ParamsType extends any[],
    MutationType extends LiveMutation<LiveDirectoryItem, any>,
  >(
    mutationCreator: (...args: ParamsType) => MutationType
  ): (...args: ParamsType) => ReturnType<MutationType> {
    return (...args: ParamsType) => {
      return this.mutate(mutationCreator(...args));
    };
  }

  onChangeMutator<
    ValueType extends string,
    ParamsType extends {target: {value: ValueType}},
    MutationType extends LiveMutation<LiveDirectoryItem, any>,
  >(
    mutationCreator: (e: ValueType) => MutationType
  ): (e: ParamsType) => ReturnType<MutationType> {
    return (e: ParamsType) => {
      return this.mutate(mutationCreator(e.target.value));
    };
  }
}
