import "reflect-metadata";
import equal from "fast-deep-equal";
import {
  makeAutoObservable,
  flow,
  flowResult,
  IReactionDisposer,
  reaction,
} from "mobx";
import {JsonTypes} from "typedjson";
import {AutoSaveableKey, AutoSaveableModel} from "../../../common";
import {debounce} from "./helpers";

interface AutoSaveManagerOptions {
  autosave: boolean;
}

export interface AutoSaveable {
  asJSON: JsonTypes;
}

export interface AutoSaveableClass {
  fromJSON(object: Record<string, unknown>): AutoSaveable | undefined;
}

export const key = (type: AutoSaveableModel, id: string): AutoSaveableKey =>
  `${type}:${id}`;
const unkey = (key: AutoSaveableKey) =>
  key.split(":") as [AutoSaveableModel, string];

export type PersistenceProvider = (
  type: AutoSaveableModel,
  id: string,
  object: JsonTypes
) => Promise<boolean>;

export class AutoSaveManager {
  private options: AutoSaveManagerOptions;
  private provider?: PersistenceProvider;

  saving = false;
  dirty = false;

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakRef
  private cachedDocument = new Map<AutoSaveableKey, AutoSaveable>();

  private currentJSON = new Map<AutoSaveableKey, JsonTypes>();

  private dirtyKeys = new Set<AutoSaveableKey>();

  private saveHandlers = new Map<string, IReactionDisposer>();

  constructor(
    provider?: PersistenceProvider,
    options: AutoSaveManagerOptions = {autosave: true}
  ) {
    makeAutoObservable(this, {
      _internalPersist: flow,
    });

    this.options = options;
    this.provider = provider;
  }

  getFromCache(type: AutoSaveableModel): [string, AutoSaveable][] {
    const keys = Array.from(this.cachedDocument.keys()).filter(k =>
      k.startsWith(`${type}:`)
    );

    return keys.map(k => {
      const [, id] = unkey(k);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return [id, this.cachedDocument.get(k)!];
    });
  }

  register(type: AutoSaveableModel, id: string, object: AutoSaveable) {
    this.cachedDocument.set(key(type, id), object);

    this.saveHandlers.set(
      key(type, id),
      reaction(
        () => object.asJSON,
        json => {
          this.updated(type, id, json, object);
        }
      )
    );
  }

  unregister(type: AutoSaveableModel, id: string) {
    const k = key(type, id);

    const handler = this.saveHandlers.get(k);
    if (handler) {
      handler();
      this.saveHandlers.delete(k);
    }

    this.cachedDocument.delete(k);
    this.currentJSON.delete(k);
    this.dirtyKeys.delete(k);
  }

  private updated(
    type: AutoSaveableModel,
    id: string,
    json: JsonTypes,
    object: AutoSaveable
  ) {
    if (!this.options.autosave) return;

    const documentIsDirty = !equal(this.currentJSON.get(key(type, id)), json);

    if (documentIsDirty) {
      this.dirtyKeys.add(key(type, id));
    }

    // if dirty=true, we don't want to then set it to false here
    // because we still need to do a save
    this.dirty = this.dirty || documentIsDirty;

    this.currentJSON.set(key(type, id), json);
    this.cachedDocument.set(key(type, id), object);

    this.saveEventually();
  }

  dispose() {
    this.saveHandlers.forEach(handler => handler());
    this.saveHandlers.clear();

    this.currentJSON.clear();
    this.cachedDocument.clear();
    this.dirtyKeys.clear();
  }

  // this is a debounced function that we can call whenver the object changed
  // it will "eventually" actually save the object to the database
  @debounce(1000)
  private saveEventually() {
    this.persistNow();
  }

  // this will save the object to the database right away
  private async persistNow() {
    await flowResult(this._internalPersist());
  }

  // We have to wrap this in a generator for the async mobx actions
  // to work properly.
  // https://mobx.js.org/actions.html#asynchronous-actions
  *_internalPersist() {
    if (!this.provider || !this.dirty) return;

    this.saving = true;

    const keysToSave = Array.from(this.dirtyKeys);

    for (const k of keysToSave) {
      const [type, id] = unkey(k);
      yield this.provider(type, id, this.currentJSON.get(k));
    }

    this.saving = false;
    this.dirty = false;

    // remove the dirty IDs
    keysToSave.forEach(id => this.dirtyKeys.delete(id));
  }
}
